import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
    providedIn: 'root',
})
export class MfToasterService {
    constructor(private toastr: ToastrService) {}

    public success(text: string): void {
        this.toastr.success(text);
    }

    public warning(text: string): void {
        this.toastr.warning(text);
    }

    public error(text?: string, disableTimeOut: boolean = false): void {
        this.toastr.error(text, undefined, { disableTimeOut: disableTimeOut });
    }
}

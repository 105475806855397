import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
    VERSION: require('../../package.json').version,
    production: true,
    RELEASE_STAGE: 'development',
    appUrl: 'localhost:4300',
    recaptchaApiKey: '6Ld843IcAAAAAGBGrFVX-xuAb80d60uhNngd4gTT',
    service: {
        protocol: 'http://',
        rootUrl: 'localhost',
        apiPort: ':9090',
        apiUrl: '/booking/patient/api',
        erpApiUrl: '/booking/erp/api',
        communicationapi: '/booking/erp/communicationapi',
    },
    patient: {
        protocol: 'http://',
        rootUrl: 'localhost',
        port: ':9090',
        url: '/booking/patient/api',
    },
    accounting2: {
        protocol: 'http://',
        rootUrl: 'localhost',
        port: ':5999',
        url: '/accounting2',
    },
    assistance: {
        v1: {
            protocol: 'http://',
            rootUrl: 'localhost',
            port: ':9090',
            url: '/booking/assistance/api',
        },
        v2: {
            protocol: 'http://',
            rootUrl: 'localhost',
            port: ':8092',
            url: '/booking/v2/assistance/api',
        },
        v3: {
            protocol: 'http://',
            rootUrl: 'localhost',
            port: ':5550',
            url: '/booking/asv2/assistance/api',
        },
    },
    widget: {
        protocol: 'http://',
        rootUrl: 'localhost',
        port: ':9190',
        url: '/booking/eav2/widgetapi',
    },
    erp: {
        protocol: 'http://',
        rootUrl: 'localhost',
        port: ':9190',
        url: '/booking/eav2/erp',
    },
};

import * as _ from 'lodash';
import { Bpi } from './../../_enums/bpi.enum';
import { Serializable } from './../deserialize.interface';
import { Service } from './service.model';

export class Contractor implements Serializable<any> {
    id: number;
    name: string;
    address: string;
    description: string;
    webId: string;
    services: Service[];
    subcontractors: Contractor[];
    color: string;
    locations: any[];
    phone: string;
    post: string;
    postNumber: string;
    email: string;
    bgColor: string;
    fgColor: string;
    academicTitle: string;
    education: string;
    employmentStart: Date;
    employmentEnd: Date;
    subspecializations: string[];
    groupTags: any[];
    bpiType: Bpi;
    isPgw: boolean;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }
        this.id = input.id;
        this.name = input.name;
        this.address = input.address;
        this.description = input.description;
        this.webId = input.webId;
        this.color = input.color;
        this.locations = input.locations;
        this.phone = input.phone;
        this.post = input.post;
        this.postNumber = input.postNumber;
        this.email = input.email;
        this.bgColor = input.bgColor;
        this.fgColor = input.fgColor;
        this.academicTitle = input.academicTitle;
        this.education = input.education;
        this.employmentStart = input.employmentStart;
        this.employmentEnd = input.employmentEnd;
        this.subspecializations = input.subspecializations;
        this.bpiType = input.bpi;
        this.isPgw = input.isPgw;

        if (input.groupTags) {
            this.groupTags = input.groupTags;
        }
        // debugger;
        if (input.services) {
            this.services = input.services;
        }
        // debugger;
        if (input.subcontractors) {
            this.subcontractors = input.subcontractors.map((conr: any) => {
                return new Contractor().deserialize(conr);
            });
        }
        // this.services = new Services().deserialize(input.services);

        return this;
    }
}

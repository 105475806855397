import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HrefPreventDefaultDirective } from '../../_directives/href-prevent-default.directive';
// import { UnwrapTagDirective } from '../../_directives/unwrap-tag.directive';
import { HrefTargetDirective } from '../../_directives/href-target.directive';
import { ReplaceTargetPipe } from '../../_pipes/replace-target.pipe';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
    declarations: [FooterComponent, HrefPreventDefaultDirective, LoaderComponent, HrefTargetDirective, ReplaceTargetPipe],
    exports: [FooterComponent, HrefPreventDefaultDirective, LoaderComponent, HrefTargetDirective, ReplaceTargetPipe],
    imports: [CommonModule, RouterModule],
})
export class LayoutModule {}

import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
    transition('SelectSlotPage => ReviewReservationPage, ReviewReservationPage => ConfirmedReservationPage', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
            }),
        ]),
        query(':enter', [style({ left: '150%', opacity: 0 })]),
        query(':leave', animateChild()),
        group([
            query(':leave', [animate('300ms ease-out', style({ left: '-150%', opacity: 0 }))]),
            query(':enter', [animate('300ms ease-out', style({ left: '0%', opacity: 1 }))]),
        ]),
        query(':enter', animateChild()),
    ]),
    transition('ReviewReservationPage => SelectSlotPage, ConfirmedReservationPage => ReviewReservationPage', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
            }),
        ]),
        query(':enter', [style({ left: '-150%', opacity: 0 })]),
        query(':leave', animateChild()),
        group([
            query(':leave', [animate('300ms ease-out', style({ left: '150%', opacity: 0 }))]),
            query(':enter', [animate('300ms ease-out', style({ left: '0%', opacity: 1 }))]),
        ]),
        query(':enter', animateChild()),
    ]),
]);

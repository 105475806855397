import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
    VERSION: require('../../package.json').version,
    production: true,
    RELEASE_STAGE: 'staging',
    appUrl: 'https://test-booking.eambulanta.si',
    recaptchaApiKey: '6Ld843IcAAAAAGBGrFVX-xuAb80d60uhNngd4gTT',
    service: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        apiPort: '',
        apiUrl: '/booking/patient/api',
        erpApiUrl: '/booking/erp/api',
        communicationapi: '/booking/erp/communicationapi',
    },
    patient: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/patient/api',
    },
    accounting2: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si',
        port: '',
        url: '/accounting2',
    },
    assistance: {
        v1: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/assistance/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/v2/assistance/api',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'test-app.eambulanta.si/api/booking',
            port: '',
            url: '/booking/asv2/assistance/api',
        },
    },
    widget: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/eav2/widgetapi',
    },
    erp: {
        protocol: 'https://',
        rootUrl: 'test-app.eambulanta.si/api/booking',
        port: '',
        url: '/booking/eav2/erp',
    },
};

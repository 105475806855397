import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as _ from 'lodash';
import { environment } from './../../environments/environment';
import { ComLoadingComponent } from './../_components/common/com-loading/com-loading.component';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    private overlayRef: OverlayRef;
    constructor(private titleService: Title, private overlay: Overlay) {}

    public showLoading(message: string = 'Prosimo počakajte...') {
        // Returns an OverlayRef (which is a PortalHost)
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create();
        }
        // Create ComponentPortal that can be attached to a PortalHost
        const spinnerOverlayPortal = new ComponentPortal(ComLoadingComponent);
        if (!this.overlayRef.hasAttached()) {
            const component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
        }
        // component.instance.message = message;
    }

    public hideLoading() {
        if (!!this.overlayRef) {
            this.overlayRef.detach();
        }
    }
    getWidgetUrl(path?: string): string {
        //http://localhost:9090/booking/widgetapi

        if (!path) {
            path = '';
        }
        return environment.widget.protocol + environment.widget.rootUrl + environment.widget.port + environment.widget.url + '/' + path;
    }

    getErpUrl(path?: string): string {
        if (!path) {
            path = '';
        }
        return environment.erp.protocol + environment.erp.rootUrl + environment.erp.port + environment.erp.url + '/api/' + path;
    }

    getPromotionUrl(path?: string): string {
        if (!path) {
            path = '';
        }
        return environment.erp.protocol + environment.erp.rootUrl + environment.erp.port + environment.erp.url + '/public/promapi/' + path;
    }

    getPatientUrl(path?: string): string {
        //http://localhost:9090/booking/patient/api

        if (!path) {
            path = '';
        }
        return environment.patient.protocol + environment.patient.rootUrl + environment.patient.port + environment.patient.url + '/' + path;
    }

    getAccounting2Url(path?: string): string {
        //http://10.230.33.13:5999/accounting2

        if (!path) {
            path = '';
        }
        return (
            environment.accounting2.protocol +
            environment.accounting2.rootUrl +
            environment.accounting2.port +
            environment.accounting2.url +
            '' +
            path
        );
    }

    getReservationsUlr(path?: string) {
        if (!path) {
            path = '';
        }
        return environment.service.protocol + environment.service.rootUrl + environment.service.apiPort + environment.service.apiUrl + '/' + path;
    }

    getAssistanceUrl(path?: string, version: number = 1): string {
        if (!path) {
            path = '';
        }
        const ver = <'v1' | 'v2' | 'v3'>`v${version}`;

        return (
            environment.assistance[ver].protocol +
            environment.assistance[ver].rootUrl +
            environment.assistance[ver].port +
            environment.assistance[ver].url +
            '/' +
            path
        );
    }

    public checkStructError(data: any, code: string): boolean {
        const err = _.get(data, 'error.errors[0]', undefined);
        if (err) {
            if (err.code === code) {
                return true;
            }
        }
        return false;
    }
    public changeAccentColor(color: string): void {
        this.changeThemeColors('--accent-color', color);
    }

    public changeThemeColors(name: string, color: string): void {
        document.documentElement.style.setProperty(name, color);
    }

    public isJson(str: string): boolean {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public safeParseJson(str: string): any {
        try {
            return JSON.parse(str);
        } catch (e) {
            return undefined;
        }
    }

    public precisionRound(number: number, precision: number) {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    }

    uuid(length: number = 8): string {
        var i, random;
        var result = '';
        // for (i = 0; i < 32; i++)
        for (i = 0; i < length; i++) {
            random = (Math.random() * 16) | 0;
            if (i === 8 || i === 12 || i === 16 || i === 20) {
                result += '-';
            }
            result += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
        }

        return result;
    }
}

import * as moment from 'moment';
import { Serializable } from '../deserialize.interface';
import { Slot } from './slot.model';

export class DaySlot implements Serializable<any> {
    // subcontractorId:number;
    // subcontractorName:string;
    date: moment.Moment;
    slots: Slot[];
    showLength: number = 3;
    deserialize(input: any) {
        this.date = moment(input.date);
        this.slots = input.slots;
        // this.subcontractorName = input.subcontractorName;
        // this.subcontractorId = input.subcontractorId;
        if (input.showLength) {
            this.showLength = input.showLength;
        }

        return this;
    }
}

<div toastContainer></div>
<div [class]="templateStyleClass" style="display: flex; justify-content: center; align-items: center; flex-flow: wrap; min-height: min-content">
    <div [@routeAnimations]="prepareRoute(outlet)" class="w-100">
        <router-outlet #outlet="outlet"></router-outlet>
    </div>
</div>
<app-footer [guidData]="cData" class="d-none"></app-footer>

<!-- Element for proper sizing of parent iframe -->
<div data-iframe-height></div>

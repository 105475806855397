import { Serializable } from '../deserialize.interface';
import { DaySlot } from './day-slot.model';

export class SubcontractorGroup implements Serializable<any> {
    // start: moment.Moment;
    // end: moment.Moment;
    // assetId: number;
    subcontractorId: number;
    subcontractorName: string;
    subcontractorTitle: string;
    price: number;
    daySlot: DaySlot[];
    hash: string;
    deserialize(input: any) {
        // this.start = input.start;
        // this.end = input.end;
        // this.assetId = input.assetId;
        this.subcontractorId = input.subcontractorId;
        this.subcontractorName = input.subcontractorName;
        this.subcontractorTitle = input.subcontractorTitle;
        this.daySlot = input.daySlot;
        this.price = input.price;
        this.hash = input.hash;

        return this;
    }
}

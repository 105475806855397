import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BaseState } from '../_state/base/base.state';

@Injectable()
export class StorageService {
    constructor(private store: Store) {}

    getToken(): string {
        return this.store.selectSnapshot(BaseState.getAccessToken);
    }

    getItem(key: string) {
        return localStorage.getItem(key) || '';
    }

    setItem(key: string, content: any) {
        if (typeof content !== 'string') {
            content = JSON.stringify(content);
        }
        localStorage.setItem(key, content);
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }

    appendObject(key: string, content: any) {
        let existingContent = JSON.parse(this.getItem(key));
        if (!existingContent) {
            existingContent = {};
        }

        existingContent = { ...existingContent, ...content };
        this.setItem(key, existingContent);
    }

    removeObject(key: string, objectKey: string) {
        let existingContent = JSON.parse(this.getItem(key));
        delete existingContent[objectKey];
        this.setItem(key, existingContent);
    }

    getObjectItem(key: any, objectKey: any) {
        let existingContent = JSON.parse(this.getItem(key));
        if (!existingContent) {
            return null;
        }
        return existingContent[objectKey];
    }
}

import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment.local';

@Component({
    selector: 'app-pbl-version',
    templateUrl: './pbl-version.component.html',
    styleUrls: ['./pbl-version.component.scss'],
})
export class PblVersionComponent implements OnInit {
    public version = `v${environment.VERSION}`;

    constructor() {}

    ngOnInit() {}
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WidgetInfo } from './../../../_model/common/widget-info.model';
import { HelperService } from './../../../_services/helper.service';
import { HttpClientService } from './../../../_services/http-client.service';

@Injectable({
    providedIn: 'root',
})
export class WidCommonService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}
    public getWidgetData(wkey?: string): Observable<WidgetInfo> {
        return this.httpClient.get(this.helper.getWidgetUrl(`widgets/${wkey}`));
        // return this.httpClient.get(this.helper.getPatientUrl(`widget/${wkey}`));
    }
}

import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BaseState } from '../_state/base/base.state';

@Injectable({
    providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: any): void {
        const store = this.injector.get(Store);
        const data = store.selectSnapshot(BaseState.getWidgetInfo);
        if (data == undefined) {
            //redirect
            const route = this.injector.get(ActivatedRoute);
            const wid = route.snapshot.queryParams?.wid;
            if (wid) {
                const router = this.injector.get(Router);
                router.navigate(['/public/widget/', wid]).then(() => {
                    window.location.reload();
                });
            }
        }
        throw error;
    }
}
